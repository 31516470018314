.products-list-item td {
  font-size: 0.8rem;
  padding: 3px 7px;
  transition: all 300ms;
  vertical-align: top;
}

.products-list-item:hover td {
  background-color: rgba(2, 110, 110, 0.3);
  cursor: pointer;
}

.products-list-item.non-disponible {
  opacity: 0.5;
}

.products-list-item.selected td {
  background-color: rgba(2, 110, 110, 0.3);

}

.products-list-item .featured {
  color: #FFD700;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 4;
}

.products-list-item .cover {
  position: relative;
  width: 80px;
  height: 60px;
}

.products-list-item .cover img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

}