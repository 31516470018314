table.table-sm {
  font-size: 0.8rem;
}

table.table-sm th,
table.table-sm td {
  padding: 0.5rem 0.5rem;
}

table thead th {}

table thead th {
  text-align: center;
  vertical-align: top;
  background-color: #ccc !important;
  font-size: 0.7;
  font-weight: normal;
}