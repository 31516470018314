.order-item {}

.order-item>td {
  padding: 2px 5px;
  border-bottom: 1px solid #999;
  vertical-align: top;

}

.order-item>td.prodRef {
  width: 120px;
}

.order-item>td.price {
  width: 80px;
}

.order-item>td.itQuantity {
  font-size: 0.9rem
}

.order-item svg {
  font-size: 1rem;
  color: #026E6E
}