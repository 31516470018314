.form-group {
  border: 1px solid transparent;
}

input.form-control,
textarea.form-control,
select.form-control {
  border-color: #def2e6 !important;
}

label.form-group-label {
  font-size: 0.75rem;
  margin-bottom: 0px;
  font-weight: bold;

}


label.radio-label,
label.checkbox-label {
  font-size: 0.8rem;
  position: relative;
  padding-left: 20px;

}

label.radio-label input,
label.checkbox-label input {
  margin-right: 5px;
  position: absolute;
  top: 4px;
  /* voir sur le formulaire d'édition de produits en cas de modifications */
  left: 0;
}

span.required {
  display: inline-block;
  margin-left: 2px;
  font-size: 1rem
}

label[required]:after,
span.required:after {
  content: '*';
  font-size: 110%;
  color: red;
}


.alert-form-error {
  white-space: pre-wrap !important;
  font-size: 0.8rem;
  padding: 7px 15px !important;
}

.form-control.error {
  border-color: red !important;
}

button[disabled] {
  cursor: no-drop !important;

}


.carret::after {
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  transform: rotate(225deg);
  margin-left: 7px;
  position: relative;
  top: 1px;
  transform: all 200ms;
}

.carret.desc::after {
  transform: rotate(45deg);
  top: -2px;
}