header {
  padding: 5px 25px 0;
  position: fixed;
  width: 100%;
  z-index: 9000;
}



#header-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

#header-main-left,
#header-main-right {
  flex: 0 0 200px;
}

#header-main-center {
  display: flex;
  justify-content: center;
}

#header-main-right {
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: flex-end;
}






header .logo {
  display: inline-block;
}

header .logo img {
  max-width: 100%;
}

header a {
  text-decoration: none;
}

.nav-links {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.nav-links a {

  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 90px;
  font-size: 0.7rem;
  line-height: 1;
}

.nav-links svg {
  height: 30px;
  flex: 1 0 30px;
}

/* Responsive styles */
.burger-menu {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  position: fixed;
  z-index: 9001;
  top: 20px;
  right: 10px;
}

#header-responsive-menu {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: #f8f9fa;
  z-index: 9000;
  width: 0;
  overflow: hidden;
  transition: all 1000ms;
}

#header-responsive-menu ul {
  width: 250px;
  margin: 100px 35px 0 50px;
}

#header-responsive-menu a:hover {
  text-decoration: underline;
  color: var(--green);
}

@media (max-width: 991px) {
  #header-main-left {
    width: 100%;
  }

  #header-main-center,
  #header-main-right {
    display: none !important;
  }

  .burger-menu {
    display: block;
  }

  .responsive-menu-on #header-responsive-menu {
    width: 400px;
    transition: width 0;
  }
}

#app-header #header-main-right a {
  margin: 0 5px;
}

#admin-header {
  background-color: #f8f9fa;
  z-index: 1000;
}

#admin-header .nav-links a {
  height: 50px;
}


#header-main-right .right-modules {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}