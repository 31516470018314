.deliveries-list-item {
  border-bottom: 1px solid #aaa;


}

.deliveries-list-item.selected {
  border-radius: 7px;
  border: 1px solid var(--green-dark);
  background-color: rgba(2, 110, 110, 0.2);
}


.deliveries-list-item h4 {
  font-size: 0.9rem;
}

.deliveries-list-item h5 {
  font-size: 0.85rem;
}

.deliveries-list-item p {
  font-size: 0.8rem;
  max-height: 100px;
  overflow: hidden;
  transition: all 200ms;
}

.deliveries-list-item .checkbox svg {
  position: relative;
  top: -5px
}

.deliveries-list-item .actions span {
  padding: 0px 2px;
  border-radius: 7px;
  line-height: 1;
}



.deliveries-list-item.collapsed p {
  max-height: 0;
  ;
}