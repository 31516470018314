#orders {}


#orders tr.year th,
#orders tr.year td,
#orders tr.spacer td {
  background-color: white !important;
  border-bottom: none !important;
}

#orders tr.year th {
  font-size: 1.2rem;

}

#orders tr.bg>* {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}