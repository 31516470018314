#CustomersList {}

#CustomersList .table-container {
  height: 600px;
  overflow-y: auto;
  max-height: 100%;
}


#CustomersList thead th {
  position: sticky;
  top: 0;

  z-index: 2;
}