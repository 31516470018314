div.admin-layout {
  padding: 35px 0;
}

div.admin-layout div.page-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;

}

div.admin-layout div.page-title h1 {
  text-transform: uppercase;
  font-size: 1.5rem;
  color: var(--green);
}


div.admin-layout thead th {
  vertical-align: middle;
}

div.admin-layout .uploader {
  width: 2rem;
}

div.admin-layout .uploader .btn-upload {
  font-size: 1.05rem;
  color: var(--green);
}