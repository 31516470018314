#thread {}

#thread .post {
  font-size: 0.9rem;
}

#thread p {
  margin: 0 0 10px 0;
}

#thread .attachments {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

#thread .attachments li {
  flex: 0 0 180px;
  padding-top: 180px;
  position: relative;
  overflow: hidden;
}

#thread .attachments img {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 170px;
  height: 170px;
  object-fit: cover;
}

.modal-post-edit {}

.modal-attachment-show .modal-dialog {
  max-width: 96% !important;
  width: 992px !important;
}


.modal-attachment-show .attachment-wrapper {

  height: 85vh !important;
  width: 100% !important;
}

.modal-attachment-show .modal-dialog img {


  max-height: 85vh !important;
  max-width: 100% !important;
}