@keyframes swal2-show {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }

}

#swal2-html-container {
  padding: 36px 0 0 0 !important;
  margin: 0 25px 25px !important;
  user-select: text;

}

div.swal2-actions {
  margin: 0em auto 0 !important;
}