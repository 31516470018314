#admin-home {}

#admin-home .module {
  border-radius: 10px;
  overflow: hidden;

}

#admin-home .module .container {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  max-height: 100%;
}

#admin-home .module .content {
  width: 90% !important;
  margin: 0 auto;
  padding: 10px 0;

}


#admin-home h4 {
  text-align: center;
  text-transform: uppercase;
  padding: 5px 0 5px;
  margin: 0 0 5px;
}

#admin-home .main h4 {
  background-color: var(--green);
  color: white;
  font-size: 1rem;

}


#admin-home .side h4 {
  background-color: #ddd;
  color: #333;
  font-size: 0.9rem;

}

#admin-home #AdminOrdersList,
#admin-home #AdminOrdersList .table-container {
  height: 500px;
}