#AdminProductFeatures {}


#AdminProductFeatures textarea {
  height: 120px;
}

#AdminProductSpecialPrices tr.removed {
  opacity: 0.3;
}

#AdminProductSpecialPrices table tbody {
  line-height: 1;
}

#AdminProductFeatures .prodPicture {
  height: 120px;
  max-width: 180px;
}

#AdminProductFeatures .prodPicture img {
  max-width: 100%;
  max-height: 100%;
}


#AdminProductRelated .wordings {
  position: relative;

}

#AdminProductRelated .prodMemo {
  display: none;

  position: absolute;
  top: 120%;
  left: 0;
  background-color: rgba(254, 255, 200, 1);
  border: 1px solid #ddd;
  padding: 7px;
  z-index: 1000;
  width: max-content;
  width: 90%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 0.8rem;
  border-radius: 7px;
}

#AdminProductFeatures .wordings:hover .prodMemo {
  display: block;

}

#AdminProductFeatures .uploader {
  height: 25px;
  width: 100%;
}

#AdminProductFeatures .uploader .btn-upload {

  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 25px;
  display: flex;
  justify-content: right;
  align-items: center;
}


#AdminProductFeatures .uploader .btn-upload svg {
  color: var(--green);



  height: 100% !important;
}