.product-type {}

.product-type a {
  width: 12px;
  height: 12px;
  border-radius: 15px;

}

.product-type.type-13 a {
  background-color: green;
}

.product-type.type-7 a {
  background-color: #999;
}