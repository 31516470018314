.main-layout {}

.main-layout .page-title {
  background-color: var(--green);
  color: white;
  text-transform: uppercase;
  padding: 30px;
  margin-bottom: 30px;
}

.main-layout .page-title h1 {
  font-size: 1.5rem;
}