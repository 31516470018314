:root {
  --gray: rgba(150, 150, 150, 1);
  --gray-9: rgba(192, 189, 196, 0.9);
  --gray-8: rgba(192, 189, 196, 0.8);
  --gray-7: rgba(192, 189, 196, 0.7);
  --gray-6: rgba(192, 189, 196, 0.6);
  --gray-5: rgba(192, 189, 196, 0.5);
  --gray-4: rgba(192, 189, 196, 0.4);
  --gray-3: rgba(192, 189, 196, 0.3);
  --gray-2: rgba(192, 189, 196, 0.2);
  --gray-1: rgba(192, 189, 196, 0.1);
  --green: #039494;
  --green-dark: #026E6E;
}

html,
body {
  font-size: 100%;
}

a,
a.default {
  color: #666;
  cursor: pointer;
}

li.nav-item {}

a.nav-link {
  color: var(--green);
  height: 100% !important;
}

a.nav-link:hover {
  color: var(--green-dark);
  border-color: transparent;
}

a.default {
  text-decoration: none;
}

a.default:hover {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

*:focus {
  outline: 0;
  box-shadow: none !important;
  border-color: inherit !important;
}

button {
  border: none;
  border-radius: 5px;
}

.border-green {
  border-color: var(--green) !important;
}

.btn-sm {
  font-size: 0.8rem !important;
}

.btn-green {
  color: #fff !important;
  background-color: var(--green) !important;
  font-weight: normal !important;
}

.btn-green:hover {
  background-color: var(--green-dark) !important;
}

.text-green {
  color: var(--green);
}

.bg-green {
  color: #fff !important;
  background-color: var(--green) !important;
}

.bg-green-dark {
  color: #fff !important;
  background-color: var(--green-dark) !important;
}

.text-gray-9,
.text-grey-9 {
  color: #999 !important;
}

.bg-gray-9 {
  background-color: #999 !important;
}

.bg-gray-a {
  background-color: #aaa !important;
}

.bg-gray-b {
  background-color: #bbb !important;
}

.bg-gray-c {
  background-color: #ccc !important;
}

.bg-gray-d {
  background-color: #ddd !important;
}

.bg-gray-e {
  background-color: #eee !important;
}

.decoration-on-hover {
  text-decoration: none;
}

.decoration-on-hover:hover {
  text-decoration: underline;
}

h1 {
  font-size: calc(1rem + 1vw);
  text-align: center;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: calc(0.8rem + 1vw);
  text-align: center;
  margin: 0;
  padding: 0;
}

.form-label {
  font-size: 0.8rem;
}

.form-group {
  margin-bottom: 15px;
}

small,
.small {
  font-size: 0.8em !important;
}

.big {
  font-size: 1.2rem !important;
}

.form-control-xs {
  min-height: calc(1.5em + 0.1rem + calc(var(--bs-border-width)* 2));
  padding: 0.15rem 0.3rem;
  font-size: 0.7rem;
  border-radius: var(--bs-border-radius-sm);
}

.nowrap {

  white-space: nowrap;
}

thead th {
  vertical-align: middle;
  background-color: #ccc !important;
  font-weight: bold !important;
  font-size: 0.75rem;
}

.text-red {
  color: red !important;
}

h1.page-title {
  font-size: 1.7rem;
}

.ck-editor__editable {
  min-height: 300px;
}

.tab-title {
  font-size: 1.4rem;
  color: #666;
}

.fs-8 {
  font-size: 0.8rem;
}

.fs-9 {
  font-size: 0.75rem;
}

.section-title {
  margin: 0;
  padding: 0;
}

h1.section-title {
  text-align: center;
  font-size: 1.5rem;
}

h2.section-title {
  text-align: center;
  font-size: 1.2rem;
}

h3.section-title {
  text-align: center;
  font-size: 1.1rem;
}

h4.section-title {
  text-align: center;
  font-size: 1rem;
}

.alert-dismissible .btn-close {
  padding: 5px;
  font-size: 10px;
}

@media screen and (max-width: 991px) {
  .container {
    width: 96% !important;
    max-width: 96% !important;
  }

  .modal-dialog {
    max-width: 96% !important;
  }
}

th .carret {
  position: relative;
  top: 0px;
  margin-left: 5px;
  font-size: 1.2rem;
  transition: all 500ms;
}

table.order-desc th .carret {
  transform: rotate(180deg);
}

.construct {
  border: 1px dotted darkolivegreen;
}