.product-rel {}



.product-rel {}

.product-rel td {
  font-size: 0.8rem;
  padding: 5px 3px;
}

.product-rel td.reference {
  width: 90px;
  text-align: center;
}

.product-rel {}

.product-rel {}