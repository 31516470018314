.delivery-select {}

.delivery-select h2 {
  font-size: 1.1rem;
  margin-bottom: 0;
  color: rgba(2, 110, 110, 1);
}

.delivery-select .form-wrapper {
  border: 1px solid rgba(2, 110, 110, 1);
  border-radius: 7px;
  background-color: rgba(200, 200, 200, 0.2);
}