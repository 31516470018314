.user-form-container {
  max-width: 400px;
  margin: 0 auto;
}

.user-form-container .form-group-submit {
  padding-top: 25px;

}


.password-features {

  list-style: none;
  padding: 0;
  margin: 0 0 25px 0;
}

.password-features li {
  list-style: none;
  padding: 0px 0;
  line-height: 1.6;


}


.password-features svg {
  margin-right: 5px;
  font-size: 0.8rem;
  height: 15px;
}

.password-features .ok {
  color: green
}

.password-features .ko {
  color: red
}


/* Pour Webkit (Safari, Chrome, et Opera) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  text-align: center;
}

/* Pour Mozilla Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  text-align: center;
}