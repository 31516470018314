._ {}

.products-list-header th {
  text-align: center;
  font-size: 0.75rem;
  font-weight: normal;
  vertical-align: middle;
  background-color: #ccc !important;
  color: black;
  padding: 0 5px;
}

.products-list-header th.type {
  width: 60px;
  font-size: 0.7rem;
}

.products-list-header th.ref {
  width: 100px;
}

.products-list-header th.constructor {}

.products-list-header th.years {
  width: 100px;
}


.products-list-header th.years {
  width: 150px;
}


.products-list-header th.price {
  width: 100px;
}

.products-list-header th.stocks {
  width: 70px;
}

.products-list-header th.functions {
  width: 50px;
}