#catalog {}


#catalog .search {
  border-radius: 15px;

  color: white;
}

#catalog .catalog-select .radio-label {
  text-transform: uppercase;
  font-size: 0.9rem;
}

#catalog .catalog-select .radio-label input {
  position: relative;
  top: 1px;
}

#catalog .products-wrapper {
  border-radius: 15px;
  overflow: hidden;
}

#catalog .loading {
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}


#catalog .loading .progress {
  width: 450px;
  max-width: 80%;
  margin: 0 auto;
  background-color: white !important;
  opacity: 0.15;
  transform: rotate(180deg);

}

#catalog .loading .progress-bar {
  background-color: black !important;
  width: 100%;
}

#catalog table td {
  vertical-align: middle;
}

#catalog table tbody {
  max-height: 50vh;
  overflow-y: scroll;
}