#faq table thead th {
  font-size: 0.7rem;
  text-align: center;
  background-color: #026E6E;
  color: white;
}


#faq table td {
  font-size: 0.8rem;
}

#faq .add-thread form {
  max-width: 96%;
  width: 450px;
  margin-left: auto;
  margin-right: auto;
  border-color: #026E6E;
  background-color: #026E6E;
  color: white;
}

#faq .add-thread h2 {
  font-size: 1rem;
}