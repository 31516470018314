form.product-search-bar {
  background-color: var(--gray-2);

}

form.product-search-bar h2 {
  color: var(--green);

}

form.product-search-bar .form-group {
  position: relative;
}

form.product-search-bar .results {
  overflow-y: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 450px;
  z-index: 1010;
  border: 1px solid #999;
  background-color: white;
  overflow-y: scroll;
}



form.product-search-bar .loading-icon {

  animation: spin 1s linear infinite;
  /* Utilise l'animation nommée "spin" */
}

form.product-search-bar select {
  background: transparent !important;
  border: none;
  text-transform: uppercase;
}




@keyframes spin {
  0% {
    transform: rotate(0deg);
    /* Rotation initiale à 0 degrés */
  }

  100% {
    transform: rotate(360deg);
    /* Rotation complète à 360 degrés (une boucle complète) */
  }
}