.userSignInModal {}

.userSignInModal .modal-dialog {
  width: 500px !important;
}


.userSignInModal .modal-header {
  display: block;
}

.userSignInModal .modal-header .btn-close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.userSignInModal .logo {
  width: 40%;
}

.userSignInModal h1 {
  font-size: 1.2rem;
  margin: 0 0 015px;
  padding: 0;
}

.userSignInModal .recovery-code {}

.userSignInModal .recovery-code input {
  border: 2px solid var(--green);

}