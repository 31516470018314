.post-edit {}

.post-edit .attachments {}

.post-edit .attachments li {
  font-size: 0.8rem;
  margin: 0 10px 10px 0px;




}

.post-edit .attachments li .picture {
  width: 160px;
  height: 160px;
  border: 1px solid #999;
}

.post-edit .attachments li .picture img {
  object-fit: cover;
  width: 100%;
  height: 100%;

}