#AdminOrdersList .table-container {
  height: 600px;
  /* Taille max pour le conteneur */
  overflow-y: auto;
  max-height: 100%;
}

#AdminOrdersList thead th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}

#AdminOrdersList tbody tr td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}