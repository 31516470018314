.input-quantity * {
  line-height: 1;
}

.input-quantity span.quantity {
  width: 20px;
}

.input-quantity span.quantity {
  width: 20px;
  font-size: 1rem;
}

.input-quantity svg {
  font-size: 1.1rem;
}

.input-quantity input[type="number"] {
  border: 1px solid #eaeaea !important;
  width: 40px;
  text-align: center;

}

.input-quantity.md span.quantity {
  width: 20px;
  font-size: 1.1rem;
}

.input-quantity.md svg {
  font-size: 1.2rem;
}

.input-quantity.lg span.quantity {
  width: 50px;
  font-size: 1.5rem;
}

.input-quantity.lg svg {
  font-size: 1.5rem;
}