.modal.product-modal {

  width: 100vw !important;
  /* Occupy the 90% of the screen width */

  margin-left: auto;
  margin-right: auto;

}

.product-modal .modal-dialog {
  width: 90vw !important;
  max-width: 990px;
}

.product-modal .modal-content {
  width: 100% !important;
  max-width: 100% !important;
  flex: 1 0 100% !important;
}

.product-modal .modal-footer {
  display: none !important;

}

.product-modal .top-block {
  border: 1px solid #666;
  background: #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  border-radius: 15px;
}

.product-modal .order-block {
  border: 1px solid #026E6E;
  background: #026E6E;
  color: white;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  border-radius: 15px;
}

.product-modal h4 {
  font-size: 1.2rem;
}

.product-modal .order-block .btn {
  border-color: white !important
}

.product-modal .btn-light {
  border: 1px solid #999 !important;
}

.product-modal .previous-product {
  text-decoration: none;
  font-size: 0.8rem;

}

.product-modal .previous-product svg {
  position: relative;
  top: -1px;
}

.product-modal .right-side {
  height: calc(100% - 32px);
  border: 1px solid #026E6E;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.product-modal .right-side h2 {
  font-size: 1rem;
  text-transform: uppercase;
  color: #026E6E;
}

.product-modal .tabs {
  text-align: left;
}


.product-modal .btn-datasheet {
  padding: 5px 12px 3px 10px;
}

.product-modal .btn-datasheet svg {
  position: relative;
  top: -2px;
  margin: 0 8px 0 0;
}

.product-modal .tabs a {
  display: inline-block;
  font-size: 0.8rem;
  background-color: #039494;
  padding: 5px 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: white;
  text-decoration: none !important;
}

.product-modal .tabs a.active {
  background-color: #026E6E;
}



.product-modal .product-added .btn {
  width: 150px;
}