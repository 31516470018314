.cart table {
  width: 100%;
}

.cart table thead th {
  font-size: 0.7rem;
  text-align: center;
  background-color: #bbb;
}

.cart table td {
  font-size: 0.8rem
}

.cart table tfoot td {
  font-weight: bold;
}

.cart .btn {
  width: 200px;
}