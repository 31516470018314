#home-main {}

#home-main .container {}


#home-main .page-title {
  margin-bottom: 0 !important;
}

#home-main .top-section {
  background-color: #f8f9fa;
  padding: 60px 0;
}

#home-main .home-blocs>div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#home-main .center-section {}

#home-main .module h2 {
  font-size: 1rem;
  text-transform: uppercase;
}

#home-main .module .products {
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}

#last-products {
  box-shadow: 1px 1px 3px 0px #ddd;
}

#featured-products {}

#featured-products .card {
  height: 100%;
}

#featured-products .picture {
  padding-top: 75%;
  position: relative;
}

#featured-products img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#featured-products .prodRef {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}