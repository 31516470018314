.product-code-stock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.product-code-stock a {
  width: 12px;
  height: 12px;
  border-radius: 15px;

}

.product-code-stock.stock-2 a {
  background-color: green;
}

.product-code-stock.stock-1 a {
  background-color: orange;
}

.product-code-stock.stock-0 a {
  background-color: red;
}

.product-code-stock label {
  padding: 0 3px;
  font-size: 0.8rem;
}

.product-code-stock.stock-2 label {
  color: green;
}

.product-code-stock.stock-1 label {
  color: orange;
}

.product-code-stock.stock-0 label {
  color: red;
}